import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Otsikko = styled.h1`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
`;

const Linky = styled(Link)`
    text-decoration: none;
    color: black;

    :visited {
        color: black;
    }
`;

const Linky2 = styled(Link)`
    color: black;
    padding-bottom: 20px;

    :visited {
        color: black;
    }
`;

const Tekstip = styled.p`
    margin: 0;
    padding: 20px 20px 20px 0;
    font-size: 14px;
    line-height: 20px;
`;

const TuoteGrid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.desktop ? "1fr 1fr 1fr" : props.tablet ? "1fr 1fr" : "1fr"};
    grid-gap: 40px;
`;

function Tuote({ tuoteName, tuoteDescription, altText }) {
    let link = `/${tuoteName.charAt(0).toLowerCase() + tuoteName.slice(1).replace(/\s/g, '')}`;

    let ImageComponent;

    switch (tuoteName) {
        case "Metaka 165":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka165/1_web.jpg" alt={altText} />;
            break;
        case "Metaka 180":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka180/1_web.jpg" alt={altText} />;
            break;
        case "Metaka 200":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka200/1_web.jpg" alt={altText} />;
            break;
        case "Metaka 240":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka240/1_web.jpg" alt={altText} />;
            break;
        case "Metaka 240JL":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka240JL/1_web.jpg" alt={altText} />;
            break;
        case "Metaka 300":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka300/1_web.jpg" alt={altText} />;
            break;
        case "Metaka 320JL":
            ImageComponent = <StaticImage src="../assets/tuotekuvat/metaka320JL/1_web.jpg" alt={altText} />;
            break;
        default:
            ImageComponent = null; // In case the product name doesn't match any known products.
    }

    return (
        <div>
            <Otsikko>
                <Linky to={link}>{tuoteName}</Linky>
            </Otsikko>
            <Link to={link}>
                {ImageComponent}
            </Link>
            <Tekstip>{tuoteDescription}</Tekstip>
            <Linky2 to={link}>Tuotesivulle</Linky2>
        </div>
    )
}

export default function TuoteLista({ tuotteet, desktop, tablet }) {
    const sortedTuotteet = tuotteet.sort((a, b) => a.tuotteen_nimi.toLowerCase().localeCompare(b.tuotteen_nimi.toLowerCase()));

    const products = [
        { name: "Metaka 165" },
        { name: "Metaka 180" },
        { name: "Metaka 200" },
        { name: "Metaka 240" },
        { name: "Metaka 240JL" },
        { name: "Metaka 300" },
        { name: "Metaka 320JL" }
    ];

    return (
        <TuoteGrid desktop={desktop} tablet={tablet}>
            {products.map(({ name }) => {
                const tuote = sortedTuotteet.find(t => t.tuotteen_nimi === name);
                return <Tuote key={name} tuoteName={name} tuoteDescription={tuote.tuotteen_lyhyt_esittely} altText={name} />
            })}
        </TuoteGrid>
    );
}
